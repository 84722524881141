import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const maticTokens = {
  wmatic: WETH9[ChainId.MATIC],
  usdt: new ERC20Token(
    ChainId.MATIC,
    '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    18,
    'USDT',
    'USD Tether',
    'https://tether.to',
  ),
  city: new ERC20Token(
    ChainId.SPC,
    '0x9cbEa8a92DA83E5376941345f00db5d42BF35416',
    18,
    'CITY',
    'City',
    'https://cityswap.live',
  ),
}
