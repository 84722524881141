import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const spcTokens = {
  wspc: WETH9[ChainId.SPC],
  city: new ERC20Token(
    ChainId.SPC,
    '0xC81bff5B116183E3f160d8Faa5Ce36AD28288DCf',
    18,
    'CITY',
    'city',
    'https://cityswap.live',
  ),
  wbtc: new ERC20Token(ChainId.SPC, '0x473847BAdFdEd91DC1969daD95c75A354D36D4e6', 18, 'WBTC', 'Wrapped Bitcoin'),
  weth: new ERC20Token(ChainId.SPC, '0x06154488D151288dc9868600e5e329220D11E952', 18, 'WETH', 'Wrapped Ethereum'),
  snft: new ERC20Token(ChainId.SPC, '0x23ABB6A6460928E1cF571D0af29003f2414C3711', 18, 'SNFT', 'Specta NFT'),
  usdt: new ERC20Token(ChainId.SPC, '0xfE42e9d2D2Db394c67E2797E89E3Ebe6Ef9898C9', 18, 'USDT', 'Tether USD'),
}
