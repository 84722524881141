import { FACTORY_ADDRESS, INIT_CODE_HASH } from '../common/swap'
import IceChain from '../ice-chain'

export const spc: IceChain = {
  id: 575757,
  name: 'Spc Mainnet',
  features: ['swap', 'info', 'farms', 'bridge', 'staking', 'locks'],
  network: 'SPC',
  rpcUrls: {
    public: 'https://rpc.spectachain.io',
    default: 'https://rpc.spectachain.io',
  },
  blockExplorers: {
    default: { name: 'SpectaScan', url: 'https://explorer.spectachain.io' },
  },
  nativeCurrency: {
    name: 'SPC',
    symbol: 'SPC',
    decimals: 18,
  },
  blockInterval: 10,
  multicall: {
    address: '0xc499c42E1122A82216361EceB9719750219F98e5',
    blockCreated: 402438,
  },
  locks: {
    factoryAddress: '0x08c73d7E4D591e6D289EAec0c5C0D4209D6786b8',
  },
  wrappedNative: {
    address: '0xB996D3f07109bca6C6e318bc23d6c273FE840072',
    decimals: 18,
    symbol: 'WSPC',
    name: 'Wrapped Specta',
  },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
}
