import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      style={{
        height: "24px",
        width: "100%",
        top: 2,
      }}
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4492 16.5V10.5H10.4492V12H11.9492V16.5H9.69922V18H15.6992V16.5H13.4492ZM12.6992 6C12.4767 6 12.2592 6.06598 12.0742 6.1896C11.8892 6.31321 11.745 6.48891 11.6599 6.69448C11.5747 6.90005 11.5524 7.12625 11.5958 7.34448C11.6392 7.56271 11.7464 7.76316 11.9037 7.9205C12.0611 8.07783 12.2615 8.18498 12.4797 8.22838C12.698 8.27179 12.9242 8.24951 13.1297 8.16436C13.3353 8.07922 13.511 7.93502 13.6346 7.75002C13.7582 7.56501 13.8242 7.3475 13.8242 7.125C13.8242 6.82663 13.7057 6.54048 13.4947 6.3295C13.2837 6.11853 12.9976 6 12.6992 6Z"
        fill="#764C99"
      />
      <path
        d="M12.6992 22.5C10.6225 22.5 8.59245 21.8842 6.86574 20.7304C5.13902 19.5767 3.79321 17.9368 2.99849 16.0182C2.20377 14.0996 1.99583 11.9884 2.40098 9.95156C2.80612 7.91476 3.80615 6.04383 5.2746 4.57538C6.74305 3.10693 8.61398 2.1069 10.6508 1.70176C12.6876 1.29661 14.7988 1.50455 16.7174 2.29927C18.636 3.09399 20.2759 4.4398 21.4297 6.16652C22.5834 7.89323 23.1992 9.9233 23.1992 12C23.1992 14.7848 22.093 17.4555 20.1238 19.4246C18.1547 21.3938 15.484 22.5 12.6992 22.5ZM12.6992 3C10.9192 3 9.17913 3.52785 7.69909 4.51678C6.21905 5.50571 5.0655 6.91132 4.38431 8.55585C3.70312 10.2004 3.52489 12.01 3.87216 13.7558C4.21942 15.5016 5.07659 17.1053 6.33526 18.364C7.59394 19.6226 9.19758 20.4798 10.9434 20.8271C12.6892 21.1743 14.4988 20.9961 16.1434 20.3149C17.7879 19.6337 19.1935 18.4802 20.1824 17.0001C21.1714 15.5201 21.6992 13.78 21.6992 12C21.6992 9.61306 20.751 7.32387 19.0632 5.63604C17.3754 3.94822 15.0862 3 12.6992 3Z"
        fill="#764C99"
      />
    </svg>
  );
};

export default Icon;
