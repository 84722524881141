import { useIsMounted } from "@pancakeswap/hooks";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BottomNav from "../../components/BottomNav";
import { Box } from "../../components/Box";
import CakePrice from "../../components/CakePrice/CakePrice";
import Footer from "../../components/Footer";
import LangSelector from "../../components/LangSelector/LangSelector";
import MenuItems from "../../components/MenuItems/MenuItems";
import { SubMenuItems } from "../../components/SubMenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import Image from "next/image";
import { Text, Flex, FlexGap } from "@pancakeswap/uikit";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${MENU_HEIGHT}px;

  transform: translate3d(0, 0, 0);

  padding: 24px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 36px;
    padding-right: 36px;
  }
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: sticky;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  max-width: 100vw;
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 40px 120px;
  }
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  padding-bottom: 50px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 0px;
  }
`;

const BannerHeader = styled.div`
  background: linear-gradient(
    157.81deg,
    rgba(109, 71, 151, 0.7) 0%,
    rgba(108, 71, 151, 0.7) 0%,
    rgba(85, 62, 145, 0.7) 29.82%,
    rgba(67, 56, 141, 0.7) 51.94%,
    rgba(47, 47, 125, 0.7) 90.29%
  );
  max-height: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 40px 120px;
    max-height: 184px;
  }
`;

const BannerTitle = styled.h1`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 40px;
  }
`;

const BannerDescription = styled.p`
  font-size: 12px;
  color: #b3b4b7;
  line-height: 20px;
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 16px;
  }
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
}) => {
  const hasSubLinks = subLinks && subLinks.length > 0;
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);
  const [menuBackgroundColor, setMenuBackgroundColor] = useState("transparent");

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <AtomBox
        style={{
          background: "#222548",
        }}
      >
        <Wrapper>
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            {banner && isMounted && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>}
            <StyledNav
              style={{
                backgroundColor: showMenu ? "#2d3051" : "#2d3051",
              }}
            >
              <Flex>
                <Logo href={homeLink?.href ?? "/"} />
                <AtomBox display={{ xs: "none", md: "block" }}>
                  <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px" />
                </AtomBox>
              </Flex>
              <Flex alignItems="center" height="100%">
                <AtomBox mr="12px" display={{ xs: "none", lg: "block" }}>
                  <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />

                  {/* <Flex>
                    <Image src="/icon-crypto.png" alt="" width={24} height={24} />
                    <FlexGap width={8} />
                    <Text color="textSubtle">{cakePriceUsd}</Text>
                  </Flex> */}
                </AtomBox>
                {rightSide}
              </Flex>
            </StyledNav>
          </FixedContainer>

          <div>
            {activeSubItem?.toLowerCase() === "/pools" ||
            activeSubItem?.toLowerCase() === "/farms" ||
            activeItem?.toLowerCase() === "/locks" ? (
              <BannerHeader>
                <BannerTitle>
                  CITY{" "}
                  <span
                    style={{
                      color: "#764C99",
                    }}
                  >
                    {activeItem?.toLowerCase() === "/locks" ? "Locks" : "Pool"}
                  </span>
                </BannerTitle>
                <BannerDescription>
                  By staking your tokens, you can unlock rewards and earn passive income on your investment.
                </BannerDescription>
              </BannerHeader>
            ) : null}
            {/* Nav me */}
            {subLinks ? (
              <Flex justifyContent="space-around" overflow="hidden">
                <SubMenuItems
                  items={subLinksWithoutMobile}
                  style={{ marginTop: `${0}px`, display: "block" }}
                  activeItem={activeSubItem}
                />

                {subLinksMobileOnly && subLinksMobileOnly?.length > 0 && (
                  <SubMenuItems
                    items={subLinksMobileOnly}
                    style={{ marginTop: `${totalTopMenuHeight + 1}px`, display: "block" }}
                    activeItem={activeSubItem}
                    isMobileOnly
                  />
                )}
              </Flex>
            ) : (
              <div />
            )}
          </div>
          <BodyWrapper>
            <>{children}</>
          </BodyWrapper>
        </Wrapper>
      </AtomBox>

      {/*
      <Footer
        items={footerLinks}
        isDark={isDark}
        toggleTheme={toggleTheme}
        langs={langs}
        setLang={setLang}
        currentLang={currentLang}
        cakePriceUsd={cakePriceUsd}
        buyCakeLabel={buyCakeLabel}
        mb={[`${MOBILE_MENU_HEIGHT}px`, null, "0px"]}
      />
      */}
      <AtomBox display={{ xs: "block", md: "none" }}>
        <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
      </AtomBox>
    </MenuContext.Provider>
  );
};

export default Menu;
