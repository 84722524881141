import { ChainId } from '@pancakeswap/sdk'
import FarmsSpcPriceHelper from './575757'

// todo: make dynamic
export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.SPC:
      return FarmsSpcPriceHelper
    default:
      return []
  }
}
