import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
body {
  font-family: var(--font-monsterat), sans-serif;
   
}

  .brandName {
    font-family: var(--font-russo), sans-serif;
  }

   /* .link-wrapper {
    &:hover {
       svg {
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out ;
       }
    }
   } */

  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
