import { SerializedFarmConfig } from '../../types'
import { spcTokens } from '@pancakeswap/tokens'

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  // {
  //   pid: null,
  //   lpSymbol: 'Miidas-Wbrise LP',
  //   lpAddress: '0xf2FB0649dec00b79130923AdF2e9B518Bdd97b52',
  //   token: spcTokens.city,
  //   quoteToken: spcTokens.city,
  // },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default priceHelperLps
