import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { spcTokens } from './575757'
import { bscTokens } from './56'
import { maticTokens } from './137'

export const USD: Record<ChainId, ERC20Token> = {
  [ChainId.SPC]: spcTokens.usdt,
  [ChainId.BSC]: bscTokens.usdt, // todo: add proper BSC USDT
  [ChainId.MATIC]: maticTokens.usdt, // todo: add proper BSC USDT
}

export const ICE: Record<ChainId, ERC20Token> = {
  [ChainId.SPC]: spcTokens.city,
  [ChainId.BSC]: bscTokens.city, // todo: add proper BSC ICE
  [ChainId.MATIC]: maticTokens.city, // todo: add proper BSC ICE
}

