import React, { SVGProps } from 'react'

export const SuccessIcon = ({ props }: { props?: SVGProps<any> }) => (
    <svg
        width={101}
        height={100}
        viewBox="0 0 101 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.5002 91.6666C73.512 91.6666 92.1668 73.0118 92.1668 50C92.1668 26.9881 73.512 8.33331 50.5002 8.33331C27.4883 8.33331 8.8335 26.9881 8.8335 50C8.8335 73.0118 27.4883 91.6666 50.5002 91.6666ZM72.0406 38.2071C72.4311 37.8166 72.4311 37.1834 72.0406 36.7929C71.6501 36.4024 71.0169 36.4024 70.6264 36.7929L46.3335 61.0858L34.5406 49.2929C34.1501 48.9023 33.5169 48.9023 33.1264 49.2929C32.7359 49.6834 32.7359 50.3166 33.1264 50.7071L45.6264 63.2071C46.0169 63.5976 46.6501 63.5976 47.0406 63.2071L72.0406 38.2071Z"
            fill="#0163E0"
        />
    </svg>
)