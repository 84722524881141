import {
  Box,
  Button,
  Flex,
  InjectedModalProps,
  LinkExternal,
  Message,
  Skeleton,
  Text,
  CopyAddress,
} from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { FetchStatus } from '../../../config/constants/types'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from '../../../hooks/useAuth'
import useNativeCurrency from '../../../hooks/useNativeCurrency'
import { useGetCakeBalance } from '../../../hooks/useTokenBalance'
import { ChainLogo } from '../../Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from '../../../utils'
import { formatBigNumber } from '@pancakeswap/utils/formatBalance'
import { useBalance } from 'wagmi'

const COLORS = {
  ETH: '#f8567f',
  BNB: '#14151A',
}

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  switchView: (newIndex: number) => void
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { chain, chainId, account } = useActiveWeb3React()
  // const bnbBalance = useBalance({ addressOrName: account, chainId: ChainId.BSC })
  const nativeBalance = useBalance({ addressOrName: account })
  const native = useNativeCurrency()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useGetCakeBalance()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      {/* <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text> */}

      <div style={{ textAlign: 'center', marginBottom: 12 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ChainLogo chainId={chain?.id} />
          <Text style={{ marginLeft: 10 }} color="#fff" fontWeight={600}>
            {native.symbol} {t('Balance')}
          </Text>
        </div>
        {!nativeBalance.isFetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text fontWeight={700} style={{ fontSize: '32px' }}>
            {formatBigNumber(nativeBalance.data.value, 6)}
          </Text>
        )}
      </div>

      <CopyAddress
        buttonColor="#fff"
        style={{ backgroundColor: 'transparent', padding: '12 16', borderRadius: 12 }}
        tooltipMessage={t('Copied')}
        account={account}
        mb="24px"
      />
      {hasLowNativeBalance && (
        <Message style={{ display: 'flex', alignItems: 'center' }} variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
            <Text as="p" style={{ fontSize: 12, color: '#90A3BF' }}>
              {t('You need %currency% for transaction fees.', {
                currency: native.symbol,
              })}
            </Text>
          </Box>
        </Message>
      )}
      <div>
        {chain && (
          <Box mb="12px">
            {/* <Flex justifyContent="space-between" alignItems="center" mb="8px">
            <Flex bg={COLORS.ETH} borderRadius="16px" pl="4px" pr="8px" py="2px">
              <ChainLogo chainId={chain.id} />
              <Text color="white" ml="4px">
                {chain.name}
              </Text>
            </Flex>
            <LinkExternal href={getBlockExploreLink(account, 'address', chainId)}>
              {getBlockExploreName(chainId)}
            </LinkExternal>
          </Flex> */}
            {/* <Flex mb={2} alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">{chain.name}</Text>
              <LinkExternal
                color="#0163E0"
                style={{ color: '#0163E0' }}
                href={getBlockExploreLink(account, 'address', chainId)}
              >
                {getBlockExploreName(chainId)}
              </LinkExternal>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">
                {native.symbol} {t('Balance')}
              </Text>
              {!nativeBalance.isFetched ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <Text fontWeight={600} color="textSubtle">
                  {formatBigNumber(nativeBalance.data.value, 6)}
                </Text>
              )}
            </Flex> */}
          </Box>
        )}
        {/* <Box>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="textSubtle">{t('ICE Balance')}</Text>
            {cakeFetchStatus !== FetchStatus.Fetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{formatBigNumber(cakeBalance, 3)}</Text>
            )}
          </Flex>
        </Box> */}
      </div>
      <Button size="54px" variant="primaryPurple" width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
