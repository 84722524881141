import styled, { css } from "styled-components";
import { Flex, Box } from "../Box";

export const SubMenuItemWrapper = styled(Flex)<{ $isMobileOnly: boolean }>`
  ${({ theme }) => theme.mediaQueries.sm} {
    ${({ $isMobileOnly }) => ($isMobileOnly ? "display:none" : "")};
  }

  display: flex !important;
  align-items: center;
  padding: 24px !important;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    background: "";
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    background: "";
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 1240px;
    // margin-left: 0;
    // margin-right: 0;
    // padding-left: 20px !important;
    // padding-right: 20px !important;
  }
`;

export const ButtonNavWrapper = styled.div`
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  border-radius: 12px;
  padding: 4px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 40%;
  }
`;

const StyledSubMenuItems = styled(Flex)`
  position: relative;
  z-index: 1;
`;

const maskSharedStyle = css`
  position: absolute;
  z-index: 2;
  width: 48px;
  height: 100%;
  top: 0px;
  display: flex;
  justify-content: center;
  will-change: opacity;
  pointer-events: none;
  opacity: 0;
  transition: 0.25s ease-out opacity;

  &.show {
    pointer-events: auto;
    opacity: 1;
    transition: 0.25s ease-in opacity;
  }
`;

export const LeftMaskLayer = styled.div`
  ${maskSharedStyle}
  left: 0px;
  background: ${({ theme }) =>
    theme.isDark
      ? `linear-gradient(90deg, #27262c 29.76%, rgba(39,38,44, 0) 100%)`
      : `linear-gradient(90deg, #ffffff 29.76%, rgba(255, 255, 255, 0) 100%)`};
`;
export const RightMaskLayer = styled.div`
  ${maskSharedStyle}
  right: 0px;
  background: ${({ theme }) =>
    theme.isDark
      ? `linear-gradient(270deg, #27262c 0%, rgba(39,38,44, 0) 87.5%)`
      : `linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 87.5%)`};
`;

export const StyledSubMenuItemWrapper = styled.div`
  min-width: 153px;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px, 24px, 12px, 24px;
`;

export default StyledSubMenuItems;
