import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      style={{
        height: "24px",
        width: "100%",
        top: 2,
      }}
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1790_32581)">
        <path
          d="M13.6024 2.19512L20.6024 4.82012C20.9837 4.96305 21.3123 5.21898 21.5442 5.55368C21.7762 5.88838 21.9004 6.2859 21.9004 6.69312V12.0561C21.9004 13.7275 21.435 15.366 20.5563 16.7878C19.6776 18.2096 18.4204 19.3586 16.9254 20.1061L13.5714 21.7831C13.3631 21.8873 13.1333 21.9416 12.9004 21.9416C12.6675 21.9416 12.4377 21.8873 12.2294 21.7831L8.87539 20.1061C7.38043 19.3586 6.12316 18.2096 5.24446 16.7878C4.36576 15.366 3.90035 13.7275 3.90039 12.0561V6.69312C3.90034 6.2859 4.02459 5.88838 4.25654 5.55368C4.48849 5.21898 4.81708 4.96305 5.19839 4.82012L12.1984 2.19512C12.651 2.02545 13.1498 2.02545 13.6024 2.19512ZM12.9004 4.06812L5.90039 6.69312V12.0561C5.90062 13.356 6.26278 14.6301 6.94631 15.7358C7.62983 16.8414 8.60771 17.7349 9.77039 18.3161L12.9004 19.8831L16.0304 18.3181C17.1934 17.7367 18.1715 16.8429 18.855 15.7369C19.5385 14.6309 19.9005 13.3563 19.9004 12.0561V6.69312L12.9004 4.06812ZM12.9004 8.00012C13.3273 7.99995 13.743 8.13635 14.0868 8.38938C14.4305 8.64242 14.6844 8.99881 14.8111 9.40643C14.9378 9.81406 14.9307 10.2515 14.791 10.6549C14.6513 11.0582 14.3861 11.4063 14.0344 11.6481L13.9004 11.7331V15.0001C13.9001 15.255 13.8025 15.5002 13.6275 15.6855C13.4526 15.8708 13.2134 15.9824 12.959 15.9973C12.7046 16.0122 12.454 15.9294 12.2586 15.7659C12.0631 15.6023 11.9375 15.3702 11.9074 15.1171L11.9004 15.0001V11.7331C11.5186 11.5132 11.2202 11.1734 11.0514 10.7664C10.8826 10.3595 10.8529 9.90817 10.9669 9.4826C11.0809 9.05704 11.3322 8.68102 11.6818 8.41295C12.0315 8.14488 12.4598 7.99976 12.9004 8.00012Z"
          fill="#764C99"
        />
      </g>
      <defs>
        <clipPath id="clip0_1790_32581">
          <rect width={24} height={24} fill="white" transform="translate(0.900391)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
