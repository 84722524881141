import { useTranslation } from '@pancakeswap/localization'
import {
  Box,
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
} from '@pancakeswap/uikit'
import ConnectWalletButton from '../../ConnectWalletButton'
import Trans from '../../Trans'
import { useActiveChainId } from '../../../hooks/useActiveChainId'
import useAuth from '../../../hooks/useAuth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePendingTransactions } from '../../../state/transactions/hooks'
import { useAccount } from 'wagmi'
import WalletModal, { WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'
import LoginButton from '../../../strict/components/LoginButton'

const UserMenu = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const avatarSrc = undefined
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = useCallback((): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }, [isWrongNetwork, onPresentWalletModal, onPresentWrongNetworkModal])

  const UserMenuItems = useCallback(() => {
    return (
      <>
        <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
        <UserMenuItem
          style={{
            fontWeight: 700,
            color: '#fff',
          }}
          color="white"
          as="button"
          disabled={isWrongNetwork}
          onClick={onPresentTransactionModal}
        >
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        <UserMenuDivider />
        <LoginButton />
        <UserMenuItem as="button" onClick={logout}>
          <Flex
            alignItems="center"
            style={{
              fontWeight: 700,
              color: '#fff',
            }}
            justifyContent="space-between"
            width="100%"
          >
            {t('Disconnect')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    )
  }, [hasPendingTransactions, isWrongNetwork, logout, onClickWalletMenu, onPresentTransactionModal, t])
  const menu = useCallback(({ isOpen }) => (isOpen ? <UserMenuItems /> : null), [UserMenuItems])

  if (account) {
    return (
      <UIKitUserMenu
        className="py-5 px-4 rounded-lg bg-[#FFFFFF0D] border-[1px] border-solid border-[#FFFFFF0D]"
        account={account}
        avatarSrc={avatarSrc}
        text={userMenuText}
        variant={userMenuVariable}
      >
        {menu}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {menu}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton height={40} paddingX={16} paddingY={8}>
      <Box display={['none', , , 'block']}>
        <Trans>Connect Wallet</Trans>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
