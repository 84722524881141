import Image, { StaticImageData } from 'next/image'
import { HelpIcon } from '@pancakeswap/uikit'
import { isChainSupported } from '../../utils/wagmi'
import { memo } from 'react'
import logo56 from '../../../public/images/chains/56.png'
import logo575757 from '../../../public/images/chains/575757.png'
import logo137 from '../../../public/images/chains/137.png'
import { ChainId } from '@pancakeswap/sdk'

const logoMap: Record<ChainId, StaticImageData> = {
  56: logo56,
  137: logo137,
  575757: logo575757,
}

export const ChainLogo = memo(
  ({ chainId, width = 24, height = 24 }: { chainId: number; width?: number; height?: number }) => {
    if (isChainSupported(chainId)) {
      return (
        <Image
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px` }}
          src={logoMap[chainId]}
          width={width}
          height={height}
        />
      )
    }

    return <HelpIcon width={width} height={height} />
  },
)
