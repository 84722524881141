import { Flex, Button, Text, QuestionHelper } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useGasPriceManager } from '../../../state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from '../../../state/types'

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()
  const activeBg = '#764C99'

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text fontSize="14px" fontWeight={800}>
          {t('Default Transaction Speed (GWEI)')}
        </Text>
        {/* <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        /> */}
      </Flex>
      <Flex flexWrap="wrap">
        <Button
          px="16px"
          py="8px"
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.rpcDefault)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.rpcDefault ? activeBg : 'transparent',
            color: gasPrice === GAS_PRICE_GWEI.rpcDefault ? 'white' : '#B3B4B7',
            border: gasPrice === GAS_PRICE_GWEI.rpcDefault ? 'none' : '1px solid rgba(255, 255, 255, 0.05)',
            borderRadius: '10px',
            padding: '16px 20px',
            boxShadow: 'none',
            height: '36px',
            fontSize: '10px',
          }}
        >
          {t('Auto')}
        </Button>
        <Button
          px="16px"
          py="8px"
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.default ? activeBg : 'transparent',
            color: gasPrice === GAS_PRICE_GWEI.default ? 'white' : '#B3B4B7',
            border: gasPrice === GAS_PRICE_GWEI.default ? 'none' : '1px solid rgba(255, 255, 255, 0.05)',
            borderRadius: '10px',
            padding: '16px 20px',
            boxShadow: 'none',
            height: '36px',
            fontSize: '10px',
          }}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </Button>
        <Button
          px="16px"
          py="8px"
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.fast ? activeBg : 'transparent',
            color: gasPrice === GAS_PRICE_GWEI.fast ? 'white' : '#B3B4B7',
            border: gasPrice === GAS_PRICE_GWEI.fast ? 'none' : '1px solid rgba(255, 255, 255, 0.05)',
            borderRadius: '10px',
            padding: '16px 20px',
            boxShadow: 'none',
            height: '36px',
            fontSize: '10px',
          }}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </Button>
        <Button
          px="16px"
          py="8px"
          mr="4px"
          mt="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.instant ? activeBg : 'transparent',
            color: gasPrice === GAS_PRICE_GWEI.instant ? 'white' : '#B3B4B7',
            border: gasPrice === GAS_PRICE_GWEI.instant ? 'none' : '1px solid rgba(255, 255, 255, 0.05)',
            borderRadius: '10px',
            boxShadow: 'none',
            height: '36px',
            fontSize: '10px',
          }}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </Button>
      </Flex>
    </Flex>
  )
}

export default GasSettings
