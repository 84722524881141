import { BigNumber } from '@ethersproject/bignumber'
import { PoolCategory, SerializedPoolConfig } from './types'
import { spcTokens } from '@pancakeswap/tokens'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {} as const

export const livePools: SerializedPoolConfig[] = [
  {
    sousId: 1,
    stakingToken: spcTokens.city,
    earningToken: spcTokens.city,
    contractAddress: {
      575757: '0xf2FB0649dec00b79130923AdF2e9B518Bdd97b52',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.9259',
    version: 2,
  },
  {
    sousId: 2,
    stakingToken: spcTokens.wspc,
    earningToken: spcTokens.wspc,
    contractAddress: {
      575757: '0x8794e93cBa74f24f5E6Aa7239353AD9b0603145c',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009259',
    version: 2,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools]
