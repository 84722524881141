import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import { useIsomorphicLayoutEffect } from "framer-motion";
import debounce from "lodash/debounce";
import React, { useCallback, useRef } from "react";
import { Box } from "../Box";
import { DropdownMenuItemType } from "../DropdownMenu/types";
import MenuItem from "../MenuItem/MenuItem";
import { ChevronLeftIcon, ChevronRightIcon, OpenNewIcon } from "../Svg";
import StyledSubMenuItems, {
  LeftMaskLayer,
  RightMaskLayer,
  StyledSubMenuItemWrapper,
  SubMenuItemWrapper,
  ButtonNavWrapper,
} from "./styles";
import { Text } from "../Text";
import { SubMenuItemsProps } from "./types";
import { isMobile } from "react-device-detect";

import "./menu.css";

const SUBMENU_CHEVRON_CLICK_MOVE_PX = 100;
const SUBMENU_SCROLL_DEVIATION = 3;

const SubMenuItems: React.FC<React.PropsWithChildren<SubMenuItemsProps>> = ({
  items = [],
  activeItem,
  isMobileOnly = false,
  withMaskLayout = true,
  ...props
}) => {
  const scrollLayerRef = useRef<HTMLDivElement>(null);
  const chevronLeftRef = useRef<HTMLDivElement>(null);
  const chevronRightRef = useRef<HTMLDivElement>(null);
  const layerController = useCallback(() => {
    if (!scrollLayerRef.current || !chevronLeftRef.current || !chevronRightRef.current) return;
    const scrollLayer = scrollLayerRef.current;
    if (scrollLayer.scrollLeft !== 0) chevronLeftRef.current.classList.add("show");
    else chevronLeftRef.current.classList.remove("show");
    if (scrollLayer.scrollLeft + scrollLayer.offsetWidth < scrollLayer.scrollWidth - SUBMENU_SCROLL_DEVIATION)
      chevronRightRef.current.classList.add("show");
    else chevronRightRef.current.classList.remove("show");
  }, []);

  useIsomorphicLayoutEffect(() => {
    layerController();
  }, [layerController]);

  return (
    <>
      {items.length > 0 && (
        <AtomBox display={{ xs: "none", sm: "block" }} width="100%" asChild>
          <SubMenuItemWrapper $isMobileOnly={isMobileOnly} {...props}>
            {/* <AtomBox display={{ xs: "none", md: "none" }} justifyContent="flex-start" asChild>
            <LeftMaskLayer
              ref={chevronLeftRef}
              onClick={() => {
                if (!scrollLayerRef.current) return;
                scrollLayerRef.current.scrollLeft -= SUBMENU_CHEVRON_CLICK_MOVE_PX;
              }}
            >
              <ChevronLeftIcon />
            </LeftMaskLayer>
          </AtomBox>
          <AtomBox display={{ xs: "none", md: "none" }} justifyContent="flex-end" asChild>
            <RightMaskLayer
              ref={chevronRightRef}
              onClick={() => {
                if (!scrollLayerRef.current) return;
                scrollLayerRef.current.scrollLeft += SUBMENU_CHEVRON_CLICK_MOVE_PX;
              }}
            >
              <ChevronRightIcon />
            </RightMaskLayer>
          </AtomBox> */}

            <ButtonNavWrapper>
              <StyledSubMenuItems
                // justifyContent={[isMobileOnly ? "flex-end" : "center", null, "start"]}
                gap="12px"
                onScroll={debounce(layerController, 100)}
                ref={scrollLayerRef}
              >
                {items.map(({ label, href, icon, itemProps, type, disabled }, index, array) => {
                  const Icon = icon;
                  const isExternalLink = type === DropdownMenuItemType.EXTERNAL_LINK;
                  const linkProps = isExternalLink
                    ? {
                        as: "a",
                        target: "_blank",
                      }
                    : {};

                  const isActive = href === activeItem;

                  return (
                    label && (
                      <StyledSubMenuItemWrapper
                        key={label}
                        // mr={`${mrValue}px`}
                        style={{
                          backgroundColor: isActive ? "rgba(255, 255, 255, 0.05)" : "transparent",
                          borderBottom: isActive ? "3px solid #764C99" : "3px solid transparent",
                        }}
                      >
                        <MenuItem
                          href={href}
                          scrollLayerRef={scrollLayerRef}
                          isActive={isActive}
                          isDisabled={disabled}
                          variant="subMenu"
                          {...itemProps}
                          {...linkProps}
                        >
                          {Icon && <Icon color={isActive ? "secondary" : "textSubtle"} mr="4px" />}
                          <Text
                            margin={0}
                            color={isActive ? "#fff" : "#90A3BF"}
                            textAlign="center"
                            fontSize="14px"
                            fontWeight={700}
                            style={{
                              WebkitTextFillColor: isActive ? "#fff" : "#90A3BF",
                            }}
                          >
                            {label}
                          </Text>
                          {isExternalLink && (
                            <Box display={["none", null, "flex"]} style={{ alignItems: "center" }} ml="4px">
                              <OpenNewIcon color="textSubtle" />
                            </Box>
                          )}
                        </MenuItem>
                      </StyledSubMenuItemWrapper>
                    )
                  );
                })}
              </StyledSubMenuItems>
            </ButtonNavWrapper>
          </SubMenuItemWrapper>
        </AtomBox>
      )}
    </>
  );
};

export default SubMenuItems;
