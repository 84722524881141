import { StaticJsonRpcProvider } from '@ethersproject/providers'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc.nodereal.io'

export const SPC_PROD_NODE = 'https://rpc_test.spectachain.io/'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

export const spcRpcProvider = new StaticJsonRpcProvider(SPC_PROD_NODE)

export default null
