import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      style={{
        height: "24px",
        width: "100%",
        top: 2,
      }}
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.12 9a6.5 6.5 0 115.98 5.981M22.1 17a3 3 0 01-3 3h-2m0 0l2-2m-2 2l2 2M2.1 7a3 3 0 013-3h2m0 0l-2 2m2-2l-2-2m3.5 20a6.5 6.5 0 110-13 6.5 6.5 0 010 13z"
        stroke="#686A79"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
